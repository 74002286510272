@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600&display=swap");
:root {
  --ion-font-family: "Raleway", sans-serif !important;
}
.commonbtn {
  --box-shadow: none;
  --background: #1059b2;
  svg {
    &:first-child {
      margin-right: 7px;
      margin-left: 7px;
    }
  }
}
.container {
  @media (max-width: 767px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.react-calendar__navigation button[disabled] {
  // background-color: #ffffff;
  border-radius: 10px;
}

.nonestart-padding {
  --padding-start: 0;
}

.react-calendar {
  background: #ffffff !important;
  margin: 0 auto;
  border: 1px solid #c7c8cc !important;
  box-shadow: 0px 4px 20px rgba(48, 100, 147, 0.2);
  border-radius: 10px;
  padding: 0.8rem 0.5rem;
}

.react-calendar__viewContainer {
  .react-calendar__month-view__weekdays {
    .react-calendar__month-view__weekdays__weekday {
      @media only screen and (max-width: 320px) {
        font-size: 11px;
      }
    }
  }

  .react-calendar__tile {
    background-color: white !important;
    color: #929699;
    border-radius: 50px;
    padding: 0;

    align-items: center;
    cursor: pointer;
    margin: 0 auto;

    abbr {
      border-radius: 50px;
      min-height: 45px;
      display: inline-block;
      width: 45px;
      line-height: 45px;
      min-width: 45px;
      font-size: 14px;

      @media only screen and (max-width: 360px) {
        width: 30px;
        line-height: 30px;
        min-width: 30px;
        min-height: 30px;
      }
    }

    &.react-calendar__tile--active {
      background-color: white !important;
      abbr {
        background: var(--lightblue) !important;
        border-radius: 100%;
        color: #fff !important;
        cursor: pointer;
      }
    }

    &.highlight {
      abbr {
        // background: #a2b3c7;
        font-weight: 700;
        color: #000;
      }
      &:hover {
        background: #e6e6e6 !important;
      }
    }

    &.nonHighlight {
      pointer-events: none;
      touch-action: none !important;
    }
  }
}

.react-calendar__navigation {
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;

  .react-calendar__navigation__arrow {
    border-radius: 50px;
    min-height: 40px;
    min-width: 40px;
    font-size: 35px;
    background-color: transparent !important;
    color: #000000;
  }

  button:disabled {
    color: #bfbfbf !important;
  }

  .react-calendar__navigation__label {
    flex-grow: initial !important;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    pointer-events: none;
    color: #000000;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;

  height: 40px !important;
  /* padding: 0; */
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: url(./styles/assets/images/left-icon.svg) 0 0 no-repeat !important;
  z-index: 999;
}

.slick-prev:before,
.slick-next:before {
  color: transparent !important;
}

.slick-next {
  position: absolute;
  top: 50%;
  // right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(./styles/assets/images/right-icon.svg) 0 0 no-repeat !important;
  z-index: 999;
}

ion-footer {
  .socialicons {
    svg {
      margin: 0 15px;
      cursor: pointer;
    }
  }
}

.time-slot {
  &.active {
    background-color: var(--lightblue);
    color: #fff;
    font-weight: 600;
    border-color: var(--lightblue);
  }
}

ion-label {
  &.label {
    color: #5f5f5f;
    font-weight: 400;
    font-size: 14px;
    padding-left: 0.8rem;
    margin-bottom: 0.3rem;
    display: block;
  }
}

.react-calendar {
  .react-calendar__navigation__label__labelText {
    color: black !important;
  }
  .highlight {
    color: black;
    font-weight: 600 !important;
  }
  .nonHighlight {
    color: grey;
    pointer-events: none;
  }
  .react-calendar__tile:disabled {
    color: grey;
  }
}
ion-progress-bar {
  --buffer-background: #f4f5f7;
  --progress-background: #00d37a;
}

ion-checkbox {
  --border-color: #b2e1fe;
  --background-checked: #29265d;
  --border-color-checked: #b2e1fe;
  --border-radius: 5px;
  --border-width: 3px;
  --checkmark-color: #b2e1fe;
  margin: 0 20px 0px 0px;
  width: 25px;
  height: 25px;
}
.custom-tab {
  ion-segment-button {
    --indicator-color: #46b7f8;
    --color-hover: #6b7280;
    --indicator-height: 3px;
  }
}
.profile-btn {
  --background: transparent;
  --border-width: 1px;
  --border-color: #1059b2;
}
.selectdependents-btns ion-button {
  --padding-start: 2px;
  --padding-end: 30px;
  --box-shadow: none;
}
.btn-primary {
  --background: #1059b2;
}
.btn-light {
  --background: #ecf6fe;
  color: #1059b2;
}
.update-profile-view .input-wrapper {
  position: relative;
}
.update-profile-view {
  .input-wrapper {
    position: relative;
    ion-icon {
      bottom: inherit;
      top: 19px;
    }
  }
}
.registerdependent-one {
  form {
    display: flex;
    flex-wrap: wrap;

    > .input-wrapper {
      @media (min-width: 768px) {
        width: 50%;
        padding-right: 14px;
      }
    }
    ion-button {
      max-width: 373px;
      margin: 0 auto;
    }
  }
}
.registerdependent-two {
  form {
    display: flex;
    flex-direction: column;

    > .input-wrapper,
    ion-select {
      margin-bottom: 20px;
      @media (min-width: 992px) {
        width: calc(50% - 15px);
        margin-right: 15px;
      }
    }
    ion-button {
      @media (min-width: 992px) {
        margin-left: auto;
        width: 50%;
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        bottom: 65px;
      }
    }
  }
}
ion-header {
  .segment-button-checked {
    &.profile,
    &.sair {
      --color-checked: none !important;
    }
  }
}
.drawer-menu {
  --width: 100%;
  --background: linear-gradient(
    146.88deg,
    #1059b2 16.69%,
    #2e8ad5 35.4%,
    #46b7f8 51.68%
  );
  justify-content: flex-start !important;
  ion-header {
    background: transparent;
  }
  .menu-inner {
    justify-content: flex-start;
  }
  ion-item {
    --background: transparent;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.slick-slider {
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
}
.dependent-user {
  .left-grid-wrapper {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    ion-label > ion-text {
      font-size: 24px;
      color: #1059b2;
      font-weight: 700;
    }
    // ion-img {
    //   display: none;
    // }
  }
  .list-item {
    --background: transparent;
    --border-color: transparent;

    ion-checkbox.checkbox-checked {
      --background-checked: #29265d;
      --checkbox-background: #29265d;
    }
  }
}
.input-disabled.sc-ion-input-ios-h,
.input-disabled.sc-ion-input-md-h {
  opacity: 1;
}
.input-disabled label {
  opacity: 0.3;
}

.custom-list {
  max-height: calc(100vh - 288px);
  @media (max-width: 991px) {
    max-height: calc(100vh - 300px);
  }
  &.exam-list {
    @media (min-width: 768px) {
      max-height: calc(100vh - 460px);
      min-height: calc(100vh - 460px);
    }
  }
}

ion-footer {
  ion-segment-button {
    .icon-box {
      @media (max-width: 360px) {
        width: 35px !important;
        height: 35px !important;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
    ion-label {
      text-overflow: inherit;
      white-space: inherit;

      @media (max-width: 500px) {
        font-size: 10px !important;
        line-height: 14px !important;
      }
    }
  }
}

ion-select::part(container) {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-grad {
  background: var(
    --gradiente02,
    linear-gradient(109deg, #1059b2 27.4%, #2e8ad5 51.66%, #46b7f8 95.83%)
  );
}
ion-modal {
  --ion-background-color: #fff !important;
}
ion-modal.custom-modal {
  --height: auto;
  --width: 340px;
  --border-radius: 12px;
}
ion-modal.camera-modal {
  --height: auto;
  --width: auto;
  --border-radius: 12px;
}
.user-support-page {
  .container {
    min-height: calc(100vh - 190px);
  }
  .terms-content {
    max-width: 750px !important;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    color: #5f5f5f;

    > div {
      width: 50%;
      padding: 0 15px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
    img {
      margin: 0 auto;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #5f5f5f;
    }
    .Terms-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        vertical-align: middle;
        margin-right: 0;
      }
    }
    .cancel-label {
      font-weight: 500;
    }
  }
  .awating-content {
    max-width: 1200px !important;
    margin: 16px auto !important;
    display: flex;
    flex-wrap: wrap;
    color: #5f5f5f;

    @media (max-width: 991px) {
      margin-top: 0 !important;
      justify-content: flex-end;
    }

    > div {
      width: 50%;
      padding: 0 15px;

      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .calendarcls {
      margin-right: 10px;
    }
    .img-awating-gif {
      display: block;
    }
    .img-leftdls {
      float: none;
      display: block;
      margin: 0 auto;

      @media (max-width: 991px) {
        display: none;
      }
    }
    .cancel-btn {
      border: 0 !important;
    }
    .d-xl-none {
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
}
.terms-delete-modal {
  width: 100%;
  .Terms-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.middlearea-message {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-contain {
  contain: layout;
}
.mainvideo {
  @media (max-width: 991px) {
    margin-top: 2rem;
  }
  .upper_btn {
    > div {
      display: flex;
      align-items: center;
    }
  }
  .container {
    margin: 0 auto;
  }
  .row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .d-lg-none {
    @media (min-width: 991px) {
      display: none;
    }
  }
  .d-none {
    display: none;
  }
  .d-lg-block {
    @media (min-width: 992px) {
      display: block !important;
    }
  }
  .col-lg-8 {
    width: 66.666%;
    padding: 0 12px;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .col-lg-4 {
    width: 33.333%;
    height: 596px;
    @media (max-width: 991px) {
      width: 100%;
      height: auto;
    }
  }
  .live_support {
    position: relative;
  }
  .show_controler {
    position: absolute;
    bottom: 0;
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
  }
  .suppott_btn {
    display: flex;
    align-items: center;
  }
  .chat-design {
    position: relative;
    .card-header {
      position: absolute;
      top: 0;
      z-index: 99;
      left: 0;
      width: 100%;
      padding: 0;
    }
    .video-chat-section {
      .typemsgfiled {
        .form-control {
          appearance: none;
          border: 1px solid #ced4da;
          width: 100%;
          height: 38px;
          background: white;
        }
        .form-chat {
          @media (min-width: 992px) {
            display: block !important;
          }
        }
      }
    }
  }
  .flex-md-row {
    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
  .flex-column-reverse {
    flex-direction: column-reverse;
  }
}
.live_support_cassi .profile_name p {
  line-height: 12px !important;
}
.live_support_cassi {
  .upper_btn, .show_controler {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .show_controler {
    .profile_cassi {
      align-items: center;
      .image {
        img {
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }
}
.user-support-page {
  .incomplete-call-main {
    .voltar-btn {
      padding: 10px 45px;
      color: #fff;
    }
  }
  .main-heading {
    > div {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }
  > .container {
    margin: 0 auto;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .col {
    flex: 1 0;
  }
  .col-lg-6 {
    width: 50%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .text-center {
    text-align: center;
  }
  .d-none {
    display: none;
  }
  .d-sm-block {
    @media (min-width: 576px) {
      display: block;
    }
  }
  .d-xl-none {
    @media (min-width: 1200px) {
      display: none;
    }
  }
  .review-buttons {
    .col-7 {
      width: 58.33333333%;
      padding: 0 12px;
    }
    .col-5 {
      width: 41.66666667%;
      padding: 0 12px;
    }
    button {
      width: 100%;
    }
    .reviewbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 5px;
      }
    }
  }
  .col-4 {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-12 {
    @media (min-width: 1200px) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .review-rateDiv {
    @media (max-width: 320px) {
      padding: 5px 15px 0 15px !important;
    }
  }
}
.d-md-none {
  @media (min-width: 767px) {
    display: none !important;
  }
}
.home-background {
  &:before {
    @media (min-width: 1400px) {
      width: 36vw !important;
    }
    @media (min-width: 1600px) {
      width: 38.5vw !important;
    }
  }
}

ion-toast {
  --background: #333333 !important;
  color: #ffffff !important;
}
#search-input {
  width: 170px;
}
@supports (-webkit-overflow-scrolling: touch) {
  .custom-modal-wrapper {
    .ion-delegate-host {
      margin: 20px 8px;
    }
  }
}

.popover-chatbot {
  --max-width: 370px !important;
  --min-width: 370x !important;
  --width: 370px !important;
}
.content-ltr{
  height: calc(100vh - 177px);
}
.main-right{
   ion-card{
    background: #fff;
  }
}
.appointment-conf-success-modal{
  --ion-background-color: #ffffff !important;
  --border-radius: 14px;
  --max-width: 441px;
  --max-height: 560px;
  --overflow: auto;
@media (max-width:575px) {
  --max-width: 85%;
}
  .appointment-conf-success-modal-div{
    padding: 23px 28px;
  }
}
.aps-content-main {
  height: calc(100vh - 176px) !important;
}

@media (max-width: 767px) {
  .aps-content-main .main-left ion-label {
      text-align: center !important;
  }
  .aps-content-main .main-left ion-text {
    text-align: center !important;
    max-width: 100% !important;
  }
}
@media (max-height: 680px) {
  .aps-content-main .main-right ion-card {
    margin-bottom: 100px;
  }
}
.popover-chatbot {
  margin-top: -50px;
}

.staff-msg-box .d-flex{
  display: flex;
  width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-descls {
  a {
    text-decoration: underline !important;
  }
}