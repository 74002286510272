.succuess-review {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .review-success-title {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        color:var(--ion-color-primary);
        span {
            color: #329B6F;
        }
    }
    .review-success-img {
        width: 220px;
        margin: 50px auto;
    }
    .success-progress {
        width: 175px;
        margin: 0 auto;
    }
}
